import { IAttendance } from '../..'

export const ATTENDANCE_FINISHABLE_STATUS: IAttendance['status'] = 'accepted'

export const ATTENDANCE_ACCEPTABLE_STATUS: IAttendance['status'] =
  'awaiting_clinic_confirmation'

export const ATTENDANCE_REVIEWABLE_STATUS: IAttendance['status'] =
  'under_analysis'

export const attendanceType: Record<Required<IAttendance>['type'], string> = {
  company: 'Na empresa',
  delivery: 'Em casa',
  pickup: 'Na clínica',
}

export const parseAttendanceType = (type: IAttendance['type']) => {
  if (!type) {
    return '-'
  }
  return attendanceType[type]
}
