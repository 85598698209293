import { RolesEnum } from '../entities'

export type PermissionChecker = (permissions: string[] | undefined) => boolean

export const isAdmin: PermissionChecker = (
  permissions: string[] = []
): boolean => {
  return permissions.includes(RolesEnum.ADMIN)
}

export const isEnterprise: PermissionChecker = (
  permissions: string[] = []
): boolean => {
  return isAdmin(permissions) || permissions.includes(RolesEnum.ENTERPRISE)
}

export const isCustomer: PermissionChecker = (
  permissions: string[] = []
): boolean => {
  return isAdmin(permissions) || permissions.includes(RolesEnum.CUSTOMER)
}

export const isClinicGroupAdmin: PermissionChecker = (
  permissions: string[] = []
): boolean => {
  return (
    isAdmin(permissions) || permissions.includes(RolesEnum.CLINIC_GROUP_ADMIN)
  )
}

export const isClinicAdmin: PermissionChecker = (
  permissions: string[] = []
): boolean => {
  return (
    isClinicGroupAdmin(permissions) ||
    permissions.includes(RolesEnum.CLINIC_ADMIN)
  )
}

export const isClinicOperator: PermissionChecker = (
  permissions: string[] = []
): boolean => {
  return (
    isClinicAdmin(permissions) ||
    permissions.includes(RolesEnum.CLINIC_OPERATOR)
  )
}

export const isOnlyClinicOperator: PermissionChecker = (
  permissions: string[] = []
): boolean => {
  return permissions.includes(RolesEnum.CLINIC_OPERATOR)
}

export const isOnlyClinicAdmin: PermissionChecker = (
  permissions: string[] = []
): boolean => {
  return permissions.includes(RolesEnum.CLINIC_ADMIN)
}
