import { Grid } from '@mui/material'
import React, { FC } from 'react'
import LogoLogin from '../../assets/LogoLogin.svg'
import styled from 'styled-components'
import { mainTheme } from '../../styles/mainTheme'

export const LogoImg = styled.img`
  width: 120px;

  ${mainTheme.breakpoints.up('md')} {
    width: 250px;
  }
`

export const CreateCampaignOrderFooterWrapper = styled(Grid)`
  padding-bottom: ${mainTheme.spacing(6)}px;

  ${mainTheme.breakpoints.up('md')} {
    padding-bottom: 4%;
  }
`

export interface CreateCampaignOrderFooterProps {
  className?: string
}

const CreateCampaignOrderFooter: FC<CreateCampaignOrderFooterProps> = (
  props
) => {
  return (
    <CreateCampaignOrderFooterWrapper
      item
      className={props.className}
      marginTop={'auto'}
      display={'flex'}
      justifyContent="center"
    >
      <LogoImg
        alt="Conectamos você a quem faz vacinação. Vacinas em casa ou em clínicas parceiras"
        src={LogoLogin}
      />
    </CreateCampaignOrderFooterWrapper>
  )
}

export default CreateCampaignOrderFooter
