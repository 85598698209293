export interface IDateRange {
  startDate?: Date
  endDate?: Date
}

export enum ContentTypeEnum {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  XLSX = 'application/vnd.ms-excel',
}

export interface IClinicHeuristicParam {
  clinicsIdToInclude: string[]
  clinicsIdToExclude: string[]
  generateToAllClinics: boolean
}

export enum Locale {
  PT = 'pt',
  EN = 'en',
}

export const searchTerm = 'searchTerm'
