import { isAdmin, isClinicAdmin, PermissionChecker } from '../utils'

export enum ReportTypeEnum {
  General = 'general',
  Order = 'order',
  Clinic = 'clinic',
  Payout = 'payout',
  PayoutSummary = 'payout-summary',
  ProductStock = 'product-stock',
  Campaign = 'campaign',
  Attendance = 'attendance',
  CommercialCondition = 'commercial-condition',
  User = 'user',
  VoucherPackage = 'voucher-package',
  OrderBatchRequest = 'order-batch-request',
  UnavailableProduct = 'unavailable-product',

  TestOnly = 'test-only',
}

export const reportTypePermissionsMap = new Map<
  ReportTypeEnum,
  PermissionChecker
>()
reportTypePermissionsMap.set(ReportTypeEnum.General, isAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.Order, isClinicAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.Clinic, isAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.Payout, isClinicAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.ProductStock, isClinicAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.Campaign, isAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.Attendance, isClinicAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.CommercialCondition, isClinicAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.User, isAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.VoucherPackage, isAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.OrderBatchRequest, isAdmin)
reportTypePermissionsMap.set(ReportTypeEnum.UnavailableProduct, isAdmin)

reportTypePermissionsMap.set(ReportTypeEnum.TestOnly, isAdmin)

export interface GeneratorReportXlsxParams {
  header: {
    value: string
    type: 'string' | 'number' | 'cents' | 'date'
  }[]
  cells: (string | number | undefined)[][]
}

export interface GeneratorReportStorageParams {
  fileNameWithoutExt: string
  storageKey: string
}

export interface GeneratorReportParams {
  xlsx: GeneratorReportXlsxParams[]
  storage: GeneratorReportStorageParams
  userId: string
  sheetNames?: string[]
}
