import { IAddress, ICustomer, IOrder, IVaccineShot, IClinic } from './'
import { IDateRange } from '../sharedTypes'
import { IUser, IOrderItem, IPayout } from '../..'

export interface IAttendance {
  _id?: string
  uuid?: string
  order?: IOrder
  vendorOrderId?: string
  aliasId?: number
  orderId?: string // ref Order
  /**
   * @deprecated use `clinic._id` instead
   */
  clinicId?: string // ref Clinic
  clinic?: Pick<IClinic, '_id' | 'name'>
  itemList?: IAttendanceItem[]
  type?: 'pickup' | 'delivery' | 'company'
  pickupPointName?: string
  address?: IAddress
  notes?: string
  status?:
    | 'under_analysis'
    | 'finished'
    | 'awaiting_clinic_confirmation'
    | 'accepted'
    | 'rejected'
    | 'cancelled'
    | 'relocated'
  deliveryWindow?: IDateRange
  shippingPriceInCents?: number
  customer?: ICustomer
  rejectedReason?: string
  invoices?: IAttendanceInvoice[]
  pickupPointId?: string
  finishedAt?: Date
  reviewedAt?: Date
  payouts?: IPayout[] // virtual
}

export interface IAttendanceItem {
  orderItemId?: string // ref OrderItem
  amount?: number // TODO: remove. This is always 1
  vaccineShot?: IVaccineShot
  orderItem?: IOrderItem // virtual
}

export interface IAttendanceInvoice {
  _id?: string
  number?: string
  valueInCents?: number
  url?: string
  createdAt?: Date
  responsibleUser?: Pick<IUser, '_id' | 'name'>
  responsibleEntity?: 'clinic' | 'admin'
  status?: 'pending' | 'registered'
  issuer?: 'clinic' | 'admin'
  recipient?: 'clinic' | 'admin' | 'customer'
  orderItemIds?: string[]
}

export interface IChangeAttendanceOrdemItemsParams {
  oldAttendanceId: string
  newAttendanceId: string
  orderItemId: string
}

type AttendanceType = Required<IAttendance>['type']

export const attendanceTypeLabelMap: Record<AttendanceType, string> = {
  pickup: 'Na clínica',
  delivery: 'Em casa',
  company: 'Na empresa',
}
