import { CheckEmailPayload } from '../../state/login/types'
import api from '../api'
import axios from 'axios'

export const getMine = () => {
  return api.get('/user/me')
}

export const getUserEmailValidation = async (payload: CheckEmailPayload) => {
  return api.get(`/user/email/validation/${payload.email}`)
}

export const validateReCAPTCHA = async (responseKey: string) => {
  const baseUrl = process.env.REACT_APP_API_ENTRYPOINT
  const response = await axios.post(`${baseUrl}/recaptcha/validate`, {
    responseKey,
  })
  return response.data
}
