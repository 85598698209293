import { IAttendance } from '../Attendance'
import { IAvailableAttendance } from '../Campaign'

export interface UpdatedAttendance
  extends Record<UpdatedAttendanceSteps, StepExecution> {
  attendance: IAttendance
  newAttendance?: IAttendance
}

export const updatedAttendanceSteps = [
  'changeClinic',
  'rejectAttendance',
  'reprocessPayouts',
  'acceptAttendance',
] as const

export type UpdatedAttendanceSteps = typeof updatedAttendanceSteps[number]

export type StepExecution = {
  executed: boolean
  wasSuccessful?: boolean
}

export interface UpdateAvailableAttendanceResponseDTO
  extends IAvailableAttendance {
  updatedAttendances: UpdatedAttendance[]
}
