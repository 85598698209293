export interface INotification {
  _id?: string
  title?: string
  message?: string
  link?: string
  viewed?: boolean
  colorHex?: NotificationColors
  type?: INotificationTypes
  userId?: string

  createdAt?: Date
}

export enum INotificationTypes {
  CUSTOM = 'custom',
  NEW_ORDER = 'new_order',
  CANCELLED_ORDER = 'cancelled_order',
  REJECTED_ATTENDANCE = 'rejected_attendance',
  NEW_COMMERCIAL_CONDITION = 'new_commercial_condition',
  NEW_CAMPAIGN = 'new_campaign',
  NEW_REPORT = 'new_report',
}

export enum NotificationColors {
  BLUE = '#1979FF',
  RED = '#FF5C5C',
  PURPLE = '#5B2674',
}
