export enum SystemConfigKeys {
  ATTENDANCE_REVIEW = 'attendanceReview',
}
export enum AttendanceReviewValueEnum {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
}

export interface ISystemConfig {
  _id?: string
  key: SystemConfigKeys
  value: any
}
